<template>
    <div class="box">
        <h2>Crefo Liste wählen</h2>
        <div class="body">
            <form @submit.prevent="doSearch()">
                <div class="form-group form-float with-icon no-error">
                    <input v-model="searchTmp" id="search" placeholder=" " type="text" />
                    <label for="search">Suche</label>
                    <i @click="doSearch()" class="fal fa-search"></i>
                </div>
            </form>
            <table>
                <thead>
                    <tr>
                        <th>Key</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="clickable-row" v-for="keylist in crefoKeylists.results" v-bind:key="keylist.key">
                        <td @click="load(keylist.key)">{{ keylist.key }}</td>
                    </tr>
                </tbody>
            </table>
            <paging :page="this.page" :page-size="this.pageSize" :total="this.crefoKeylists.total" @select-page="setPage($event)" />
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            searchTmp: "",
            page: 1,
            pageSize: 25,
            search: "",
            crefoKeylists: {
                total: 0,
                results: []
            }
        };
    },
    created() {
        this.fetchData();
    },
    methods: {
        fetchData() {
            let params = {
                page: this.page,
                pageSize: this.pageSize,
                q: this.search
            };
            this.$api.get("/crefo/keylists", { params: params }).then(
                response => {
                    this.crefoKeylists = response.data.data;
                },
                () => {
                    this.$snotify.error("Fehler beim Laden der Listen");
                }
            );
        },
        load(id) {
            this.$router.push({ name: this.$route.params.goto, params: { id: id } });
        },
        doSearch() {
            this.search = this.searchTmp;
            this.fetchData();
        },
        setPage(page) {
            this.page = page;
            this.fetchData();
        }
    }
};
</script>
